import React from 'react';
import GeoGebra from 'react-geogebra';

function GeoGebraMySolAnimation(props) {

    const onLoad = () => {
        const app = window.ggbApplet;
        let obj_no = app.getObjectNumber();

        if (props.problemType === 1) {
            let j=0;
            let interval = setInterval(() => {
                let iapp = window.ggbApplet;
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    iapp.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    iapp.evalXML(dataSolvingContent);
                }
                j = j+1;
                if (j === props.myAnswerStep.length) {
                    clearInterval(interval);
                }
            },2000)

        } else if (props.problemType === 2) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            let j=0;
            let interval = setInterval(() => {
                let iapp = window.ggbApplet;
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    iapp.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    iapp.evalXML(dataSolvingContent);
                }
                j = j+1;
                if (j === props.myAnswerStep.length) {
                    clearInterval(interval);
                }
            },2000)
        } else if (props.problemType === 4) {
            console.log('난 D 타입이야')
        } else if (props.problemType === 6) {
            app.setVisible("textNo", true);
            app.setVisible("textNo1", true);
            app.setVisible("textNo2", true);
            app.setVisible("textNo3", true);
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            let j=0;
            let interval = setInterval(() => {
                let iapp = window.ggbApplet;
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    iapp.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    iapp.evalXML(dataSolvingContent);
                }
                j = j+1;
                if (j === props.myAnswerStep.length) {
                    clearInterval(interval);
                }
            },2000)
        } else if (props.problemType === 7) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            let j=0;
            let interval = setInterval(() => {
                let iapp = window.ggbApplet;
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    iapp.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    iapp.evalXML(dataSolvingContent);
                }
                j = j+1;
                if (j === props.myAnswerStep.length) {
                    clearInterval(interval);
                }
            },2000)
        }
    }

    return(
        <div className="problemmain">
            <GeoGebra
                id="ggbApplet"
                width={1600}
                height={900}
                appletOnLoad={onLoad}
                showMenuBar={false}
                showAlgebraInput={false}
                showToolBar={true}
                customToolBar="62"
                showToolBarHelp={true}
                showResetIcon={false}
                enableLabelDrags={false}
                enableShiftDragZoom={false}
                enableRightClick={false}
                errorDialogsActive={false}
                useBrowserForJS={false}
                allowStyleBar={props.allowStyleBarIn}
                preventFocus={false}
                showZoomButtons={false}
                capturingThreshold={3}
                rounding="8"
                // add code here to run when the applet starts
                showFullscreenButton={true}
                scale={1}
                disableAutoScale={false}
                allowUpscale={false}
                clickToLoad={false}
                appName="classic"
                buttonRounding={0.7}
                buttonShadows={false}
                language="ko"
                ggbBase64={props.ggbBase64In}
            />
            <br/>
        </div>
    );
}

export default GeoGebraMySolAnimation;